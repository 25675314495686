import { render, staticRenderFns } from "./InsuranceProfits.vue?vue&type=template&id=3a9f0384&scoped=true&"
import script from "./InsuranceProfits.vue?vue&type=script&lang=js&"
export * from "./InsuranceProfits.vue?vue&type=script&lang=js&"
import style0 from "./InsuranceProfits.vue?vue&type=style&index=0&id=3a9f0384&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a9f0384",
  null
  
)

export default component.exports